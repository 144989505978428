// 征迁模块角色
export const relocationProcessModuleRole = {
  survey: 'hhd:module:survey',
  solicitation: 'hhd:module:solicitation',
  rights: 'hhd:module:rights',
  assess: 'hhd:module:assess',
  sign: 'hhd:module:sign',
  vacate: 'hhd:module:vacate',
  finance: 'hhd:module:finance',
};

export const noAuthTips = '您当前的权限不够，无法操作';

// 流程计划
export const projectInfoFlowPlanRole = {
  add: 'proj:flowPlan:add',
  edit: 'proj:flowPlan:edit',
  remove: 'proj:flowPlan:remove',
  sort: 'proj:flowPlan:sort',
  // 流程计划文件
  file: {
    add: 'proj:flowPlanFile:add',
    remove: 'proj:flowPlanFile:remove',
    download: 'hhd:flowPlanFile:download',
  },
};

// 分户档案模块角色
export const projectInfoHouseholdRole = {
  list: 'hhd:archives:page',
  add: 'hhd:archives:add',
  remove: 'hhd:archives:remove',
  importArchiveNo: 'hhd:archives:importArchiveNo',
  exportArchiveNo: 'hhd:archives:exportArchiveNo',
  updateBatchGroup: 'hhd:archives:updateBatchGroup',
  updateBatchArchiveFlag: 'hhd:archives:updateBatchArchiveFlag',
  updateBatchArchivesType: 'hhd:archivesType:updateBatchArchivesType',
  upBatchRelocationStatus: 'hhd:archives:upBatchRelocationStatus',
  upBatchCalculateTypeId: 'hhd:archives:upBatchCalculateTypeId',
  batchUpdateRelocateData: 'hhd:archives:batchUpdateRelocateData',
  batchBackFieldResult: 'hhd:archives:batchBackFieldResult',
  batchImport: 'hhd:archives:batchImport',
  batchImportWholeCourseData: 'hhd:archives:batchImportWholeCourseData',
  batchExport: 'proj:report:batchExport',
  batchTotalInfo: 'proj:report:batchTotalInfo',
  copy: 'hhd:archives:copy',
  basic: {
    name: 'project:info:household:basic',
    edit: 'hhd:archives:edit',
  },
  propertyOwner: {
    name: 'project:info:household:propertyOwner',
    list: 'hhd:naturalPropertyInfo:list',
    addNaturalPropertyInfo: 'hhd:naturalPropertyInfo:add',
    editNaturalPropertyInfo: 'hhd:naturalPropertyInfo:edit',
    removeNaturalPropertyInfo: 'hhd:naturalPropertyInfo:remove',
    addEnterprisePropertyInfo: 'hhd:enterprisePropertyInfo:add',
    editEnterprisePropertyInfo: 'hhd:enterprisePropertyInfo:edit',
    removeEnterprisePropertyInfo: 'hhd:enterprisePropertyInfo:remove',
  },
  warrant: {
    name: 'project:info:household:warrant',
    list: 'hhd:warrantInfo:list',
    add: 'hhd:warrantInfo:add',
    edit: 'hhd:warrantInfo:edit',
    remove: 'hhd:warrantInfo:remove',
  },
  other: {
    name: 'project:info:household:other',
    list: 'hhd:otherAnnex:list',
    add: 'hhd:otherAnnex:add',
    remove: 'hhd:otherAnnex:remove',
    download: 'hhd:otherAnnex:download',
  },
  question: {
    name: 'project:info:household:question',
    list: 'proj:problemDetail:page',
    add: 'proj:problemDetail:add',
    edit: 'proj:problemDetail:edit',
    remove: 'proj:problemDetail:remove',
  },
  relocation: {
    name: 'project:info:household:relocation',
    reportExport: 'proj:report:export',
    annex: {
      list: 'hhd:householdAnnex:page',
      add: 'hhd:householdAnnex:add',
      remove: 'hhd:householdAnnex:remove',
      download: 'hhd:householdAnnex:download',
    },
    pricing: {
      pricingPositionList: 'hhd:pricingPosition:list',
      pricingPositionAdd: 'hhd:pricingPosition:add',
      pricingPositionEdit: 'hhd:pricingPosition:edit',
      pricingPositionRemove: 'hhd:pricingPosition:remove',
      pricingPositionCopy: 'hhd:pricingPosition:copy',
      pricingPositionTransfer: 'hhd:pricingPosition:transfer',
      pricingProcessList: 'hhd:pricingProcess:list',
      pricingProcessEdit: 'hhd:pricingProcess:addAndEdit',
      pricingPositionTypeList: 'hhd:pricingPositionType:page',
      pricingPositionTypeAdd: 'hhd:pricingPositionType:add',
      pricingPositionTypeEdit: 'hhd:pricingPositionType:edit',
      pricingPositionTypeRemove: 'hhd:pricingPositionType:remove',
      pricingPositionAnnexList: 'hhd:pricingPositionImage:list',
      pricingPositionAnnexAdd: 'hhd:pricingPositionImage:add',
      pricingPositionAnnexRemove: 'hhd:pricingPositionImage:remove',
    },
    signCanvas: {
      list: 'hhd:signCanvas:page',
      add: 'hhd:signCanvas:add',
      edit: 'hhd:signCanvas:edit',
      remove: 'hhd:signCanvas:remove',
    },
    canvas: {
      list: 'hhd:canvas:page',
      add: 'hhd:canvas:add',
      edit: 'hhd:canvas:edit',
      remove: 'hhd:canvas:remove',
    },
  },
  opeartionLogs: {
    name: 'project:info:household:opeartionLogs',
    list: 'sys:operDetailLog:page',
  },
};

// 项目设置模块角色
export const projectConfigBasicRole = {
  info: {
    name: 'project:config:basic:info',
    save: 'proj:project:edit',
  },
  group: {
    name: 'project:config:basic:group',
    // list: 'proj:group:list',
    add: 'proj:group:add',
    edit: 'proj:group:edit',
    remove: 'proj:group:remove',
  },
  role: {
    name: 'project:config:basic:role',
    list: 'proj:role:page',
    add: 'proj:role:add',
    edit: 'proj:role:edit',
    remove: 'proj:role:remove',
    menuList: 'sys:menu:list',
    roleMenu: 'sys:menu:roleMenu',
  },
  members: {
    name: 'project:config:basic:members',
    list: 'proj:groupStaff:page',
    add: 'proj:groupStaff:add',
    edit: 'proj:groupStaff:edit',
    batchEdit: 'proj:groupStaff:batchEdit',
    remove: 'proj:groupStaff:remove',
  },
  householdType: {
    name: 'project:config:basic:householdType',
    list: 'proj:householdType:page',
    add: 'proj:householdType:add',
    edit: 'proj:householdType:edit',
    remove: 'proj:householdType:remove',
  },
  placementMethod: {
    name: 'project:config:basic:placementMethod',
    list: 'proj:calculateType:page',
    add: 'proj:calculateType:add',
    edit: 'proj:calculateType:edit',
    remove: 'proj:calculateType:remove',
  },
  numberType: {
    name: 'project:config:basic:numberType',
    list: 'proj:numberRule:page',
    add: 'proj:numberRule:add',
    remove: 'proj:numberRule:remove',
  },
  attachmentType: {
    name: 'project:config:basic:attachmentType',
    list: 'hhd:annexType:page',
    add: 'hhd:annexType:add',
    edit: 'hhd:annexType:edit',
    remove: 'hhd:annexType:remove',
  },
  questionType: {
    name: 'project:config:basic:questionType',
    list: 'proj:problemType:page',
    add: 'proj:problemType:add',
    edit: 'proj:problemType:edit',
    remove: 'proj:problemType:remove',
  },
  platform: {
    name: 'project:config:basic:platform',
  },
  tableHeaderSetting: {
    name: 'project:config:basic:table-header-setting',
    add: 'proj:tableHeadSet:add',
    sort: 'proj:tableHeadSet:edit',
    remove: 'proj:tableHeadSet:remove',
  },
};

// 项目价格字典模块角色
export const projectConfigDictionaryRole = {
  list: {
    name: 'proj:dictionary:page',
    add: 'proj:dictionary:add',
    edit: 'proj:dictionary:edit',
    remove: 'proj:dictionary:remove',
    export: 'proj:dictionary:export',
  },
  type: {
    name: 'proj:dictionaryType:list',
    add: 'proj:dictionaryType:add',
    edit: 'proj:dictionaryType:edit',
    remove: 'proj:dictionaryType:remove',
    sort: 'proj:dictionaryType:sort',
    import: 'proj:dictionaryType:import',
  },
  detail: {
    name: 'proj:dictionaryDetail:list',
    add: 'proj:dictionaryDetail:add',
    edit: 'proj:dictionaryDetail:edit',
    remove: 'proj:dictionaryDetail:remove',
    sort: 'proj:dictionaryDetail:sort',
  },
};

// 项目模板设置模块角色
export const projectConfigTemplateRole = {
  list: 'proj:template:list',
  add: 'proj:template:add',
  edit: 'proj:template:edit',
  remove: 'proj:template:remove',
  download: 'proj:template:singleDownload',
  batchDownload: 'proj:template:download',
};

// 项目表单设置模块角色
export const projectConfigFormRole = {
  sheet: {
    add: 'proj:middleSheetTab:add',
    edit: 'proj:middleSheetTab:update',
    remove: 'proj:middleSheetTab:remove',
  },
  configurationFields: {
    list: 'proj:field:list',
    add: 'proj:field:add',
    edit: 'proj:field:edit',
    remove: 'proj:field:remove',
    sort: 'proj:field:drag',
  },
  backFields: {
    list: 'proj:backField:list',
    add: 'proj:backField:add',
    edit: 'proj:backField:edit',
    remove: 'proj:backField:remove',
    sort: 'proj:backField:allEdit',
  },
  signConfig: {
    list: 'proj:signConfig:page',
    add: 'proj:signConfig:add',
    edit: 'proj:signConfig:edit',
    remove: 'proj:signConfig:remove',
  },
};
