import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Checkbox, Col, Row, Space, Switch } from 'antd';

interface IPermission {
  menuId: string;
  menuType: string;
  menuName: string;
  parentId: string;
  checked: boolean;
  children: IPermission[];
}

function getFilteredMenuIds(
  permissions: IPermission[],
  allList: IPermission[],
  menuList: string[],
): string[] {
  const result: string[] = [];

  function traverse(permissionItems: IPermission[]) {
    for (const item of permissionItems) {
      if (menuList.includes(item.menuId)) {
        result.push(item.menuId);
      }
      if (item.children) {
        for (const child of item.children) {
          if (menuList.includes(child.menuId)) {
            result.push(child.menuId);
            const thirdLevelItems = allList.filter(
              (allItem) => allItem.parentId === child.menuId,
            );
            for (const thirdLevelItem of thirdLevelItems) {
              if (menuList.includes(thirdLevelItem.menuId)) {
                result.push(thirdLevelItem.menuId);
              }
            }
          }
        }
      }
    }
  }

  traverse(permissions);

  return result;
}

export default forwardRef(
  (
    props: {
      permissions: IPermission[];
      menuList: any[];
      allList: IPermission[];
    },
    ref,
  ) => {
    const [ids, setIds] = useState<string[]>([]);
    const { permissions, menuList, allList } = props;

    useEffect(() => {
      const filteredMenuIds = getFilteredMenuIds(
        permissions,
        allList,
        menuList,
      );
      setIds(filteredMenuIds);
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        getSubPermissions: () => {
          return ids;
        },
      }),
      [ids],
    );

    return (
      <div>
        {permissions.map((parent) => (
          <div key={parent.menuName}>
            <Row style={{ marginBottom: 10 }}>
              <Space>
                <div>{parent.menuName}</div>
                <Switch
                  checked={ids.includes(parent.menuId)}
                  onChange={(checked) => {
                    if (!checked) {
                      let result = ids.filter(
                        (item: any) => item !== parent.menuId,
                      );
                      const childrendIds = allList
                        .filter((item) => item.parentId === parent.menuId)
                        .map((item) => item.menuId);
                      result = result.filter(
                        (item: any) => !childrendIds.includes(item),
                      );
                      let subChildrenIds: any[] = [];
                      childrendIds.forEach((id) => {
                        subChildrenIds = subChildrenIds.concat(
                          allList
                            .filter((item) => item.parentId === id)
                            .map((item) => item.menuId),
                        );
                      });
                      result = result.filter(
                        (item: any) => !subChildrenIds.includes(item),
                      );
                      setIds(result);
                    } else {
                      let result = [...ids, parent.menuId];
                      const childrenIds = allList
                        .filter((item) => item.parentId === parent.menuId)
                        .map((item) => item.menuId);
                      result = result.concat(childrenIds);
                      let subChildrenIds: any[] = [];
                      childrenIds.forEach((id) => {
                        subChildrenIds = subChildrenIds.concat(
                          allList
                            .filter((item) => item.parentId === id)
                            .map((item) => item.menuId),
                        );
                      });
                      result = result.concat(subChildrenIds);
                      setIds(result);
                    }
                  }}
                />
              </Space>
            </Row>

            <Row gutter={[16, 16]} style={{ marginLeft: 30, marginBottom: 10 }}>
              {parent.children.map((child) => {
                // 如果是按钮
                if (child.menuType === '2') {
                  return (
                    <Col key={child.menuName} span={5}>
                      <Space>
                        <Checkbox
                          checked={ids.includes(child.menuId)}
                          onChange={(e) => {
                            const checked = e.target.checked;
                            if (checked) {
                              let result = [...ids, child.menuId];
                              const parent = allList.filter((item) => {
                                return item.menuId === child.parentId;
                              })[0];
                              if (parent && !ids.includes(parent.menuId)) {
                                result = result.concat(parent.menuId);
                              }
                              setIds(result);
                            } else {
                              const result = ids.filter(
                                (item: any) => item !== child.menuId,
                              );
                              setIds(result);
                            }
                          }}
                        >
                          {child.menuName}
                        </Checkbox>
                      </Space>
                    </Col>
                  );
                } else {
                  const subButtons = allList
                    .filter((item) => {
                      return item.parentId === child.menuId;
                    })
                    .map((button) => {
                      return {
                        ...button,
                        checked: menuList.includes(button.menuId),
                      };
                    });
                  return (
                    <div key={child.menuName} style={{ width: '100%' }}>
                      <Row style={{ marginBottom: 10, width: '100%' }}>
                        <Space>
                          <div>{child.menuName}</div>
                          <Switch
                            checked={ids.includes(child.menuId)}
                            onChange={(checked) => {
                              if (!checked) {
                                let result = ids.filter(
                                  (item: any) => item !== child.menuId,
                                );
                                const childrendIds = allList
                                  .filter(
                                    (item) => item.parentId === child.menuId,
                                  )
                                  .map((item) => item.menuId);
                                result = result.filter(
                                  (item: any) => !childrendIds.includes(item),
                                );
                                setIds(result);
                              } else {
                                let result = [...ids, child.menuId];
                                const childrenIds = allList
                                  .filter(
                                    (item) => item.parentId === child.menuId,
                                  )
                                  .map((item) => item.menuId);
                                result = result.concat(childrenIds);
                                setIds(result);
                              }
                            }}
                          />
                        </Space>
                      </Row>
                      {subButtons.length > 0 && (
                        <Row
                          gutter={[16, 16]}
                          style={{
                            width: '100%',
                            marginLeft: 30,
                            marginBottom: 10,
                          }}
                        >
                          {subButtons?.map((button) => {
                            return (
                              <Col key={button.menuName} span={5}>
                                <Space>
                                  <Checkbox
                                    checked={ids.includes(button.menuId)}
                                    onChange={(e) => {
                                      const checked = e.target.checked;

                                      if (checked) {
                                        let result = [...ids, button.menuId];
                                        const parent = allList.filter(
                                          (item) => {
                                            return (
                                              item.menuId === button.parentId
                                            );
                                          },
                                        )[0];
                                        if (
                                          parent &&
                                          !ids.includes(parent.menuId)
                                        ) {
                                          result = result.concat(parent.menuId);
                                        }
                                        const grandParent = allList.filter(
                                          (item) => {
                                            return (
                                              item.menuId === parent.parentId
                                            );
                                          },
                                        )[0];
                                        if (
                                          grandParent &&
                                          !ids.includes(grandParent.menuId)
                                        ) {
                                          result = result.concat(
                                            grandParent.menuId,
                                          );
                                        }
                                        setIds(result);
                                      } else {
                                        const result = ids.filter(
                                          (item: any) => item !== button.menuId,
                                        );
                                        setIds(result);
                                      }
                                    }}
                                  >
                                    {button.menuName}
                                  </Checkbox>
                                </Space>
                              </Col>
                            );
                          })}
                        </Row>
                      )}
                    </div>
                  );
                }
              })}
            </Row>
          </div>
        ))}
      </div>
    );
  },
);
