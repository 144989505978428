import { ColorPicker } from 'antd';
import { observer } from '@formily/react';

export default observer((props: any) => {
  const { value, onChange, ...restProps } = props;

  return (
    <ColorPicker
      value={value}
      onChange={(color) => {
        onChange(`#${color.toHex()}`);
      }}
      {...restProps}
    />
  );
});
