import React, { useState, useEffect, useRef } from 'react';
import { Select } from 'antd';
import { RefSelectProps, SelectProps, SelectValue } from 'antd/es/select';
import {
  debounce as _debounce,
  trim as _trim,
  isEqual as _isEqual,
} from 'lodash-es';
import makeRequest from '@/request';

export interface ISimpleSelectProps extends SelectProps<SelectValue> {
  mapping?: {
    key: string;
    value: string;
    __primaryKey?: string | number;
  };
  list?: any[];
  combobox?: boolean;
  getUrl?: () => string;
  getParams?: (params: any) => any;
  url?: string;
  needRequestDidMount?: boolean;
  needOnFocus?: boolean;
  needOnSearch?: boolean;
  responseHandler?: (response: IResponse) => any[];
  optionsRenderer?: (options: any[]) => any[];
  customRequest?: (params: any) => Promise<any>;
  extraParams?: any;
  enableCopy?: boolean;
  searchDebounce?: number;
}
interface IResponse {
  [propName: string]: any[];
}

const queryData = makeRequest({ url: '' });

const SimpleSelect = React.forwardRef(
  (
    {
      mapping = {
        key: 'name',
        value: 'desc',
      },
      list = [],
      url,
      getUrl,
      combobox,
      getParams,
      needRequestDidMount = false,
      needOnFocus = true,
      needOnSearch = true,
      optionsRenderer,
      responseHandler,
      optionLabelProp = 'children',
      customRequest,
      extraParams,
      onClear,
      enableCopy,
      searchDebounce,
      ...restProps
    }: ISimpleSelectProps,
    ref: React.Ref<RefSelectProps>,
  ) => {
    let [wrappedOptions, setWrappedOptions] = useState(list);
    let [searchValue, setSearchValue] = useState<any>();
    let [cachedList, setCachedList] = useState(list);
    let selectRef = useRef<RefSelectProps>(null);
    const [comboboxValue, setComboboxValue] = useState<string | undefined>();

    async function fake(value?: string) {
      const currentUrl = getUrl ? getUrl() : url;
      if (!currentUrl) return;
      let params = {
        searchValue: _trim(value),
      };

      const customizeParams = getParams ? getParams(value) : params;

      let { data: list, err } = customRequest
        ? await customRequest({ ...customizeParams, ...extraParams })
        : await queryData({
            url: currentUrl,
            method: 'GET',
            params: customizeParams,
          });
      if (err) {
        list = [];
      }
      if (responseHandler) {
        list = responseHandler(list as any);
      }
      setWrappedOptions(list);
    }

    const debounceFake = useRef(_debounce(fake, searchDebounce));

    useEffect(() => {
      if (!_isEqual(cachedList, list)) {
        setWrappedOptions(list);
        setCachedList(list);
      }
    }, [list]);

    useEffect(() => {
      if ((url || getUrl || customRequest) && needRequestDidMount) {
        fake();
      }
    }, []); // 聚焦后请求

    useEffect(() => {
      if (extraParams) {
        fake();
      }
    }, [extraParams]);

    // 聚焦后请求
    function handleFocus(e: React.FocusEvent<HTMLElement>) {
      needOnFocus && fake();
      restProps.onFocus && restProps.onFocus(e);
    }

    const optionList = [...wrappedOptions];

    if (combobox && comboboxValue) {
      const isExist = optionList.find(
        (o) => o[mapping.value] === comboboxValue,
      );

      if (!isExist) {
        optionList.unshift({
          [mapping.key]: comboboxValue,
          [mapping.value]: comboboxValue,
        });
      }
    }

    return (
      <Select
        allowClear
        ref={(node) => {
          if (ref) {
            if (typeof ref === 'function') {
              ref(node);
            }
          }
          (selectRef.current as any) = node;
        }}
        showSearch
        searchValue={searchValue}
        optionLabelProp={optionLabelProp}
        filterOption={false}
        onClear={() => {
          if (url || getUrl || customRequest) {
            fake();
          }
          onClear && onClear();
        }}
        onSearch={(value: string) => {
          enableCopy && setSearchValue(value);
          if (combobox) {
            setComboboxValue(value);
          }
          if (needOnSearch) {
            searchDebounce ? debounceFake.current(value) : fake(value);
          }
        }}
        onDropdownVisibleChange={(open) => {
          if (open && enableCopy) {
            setTimeout(() => {
              if (selectRef.current) {
                let options = (selectRef.current as any).props.options || [];
                let option =
                  options.find((item: any) => item.value === restProps.value) ||
                  {};
                if (typeof option.label === 'string') {
                  setSearchValue(option.label);
                } else {
                  setSearchValue(restProps.value);
                }
                selectRef.current.focus();
              }
            }, 100);
          } else {
            setSearchValue(undefined);
          }
        }}
        {...restProps}
        onFocus={handleFocus}
      >
        {optionsRenderer
          ? optionsRenderer(optionList)
          : optionList.map((option: any) => {
              return (
                <Select.Option
                  key={
                    mapping.__primaryKey
                      ? option[mapping.__primaryKey]
                      : option[mapping.key]
                  }
                  value={option[mapping.key]}
                  title={option[mapping.value]}
                  option={option}
                  disabled={option.disabled ? true : false}
                >
                  {option[mapping.value]}
                </Select.Option>
              );
            })}
      </Select>
    );
  },
);

export default SimpleSelect;
