import { Divider } from 'antd';
import React, { createRef, useRef } from 'react';
import BlockPermission from './BlockPermission';
import { giveRoles } from './api';
import MyModal from '../MyModal';

interface IPermissionsModal {
  programType: number;
  menuIds: number[];
  permissionList: any[];
  roleId: number;
  hasDataAuth?: boolean;
  onCancel: () => void;
}

export default (props: IPermissionsModal) => {
  const {
    onCancel,
    programType,
    menuIds,
    permissionList,
    roleId,
    hasDataAuth = true,
  } = props;

  let newPermissionList = permissionList.filter(
    (item) => item.menuName === '征迁过程模块',
  );
  newPermissionList = newPermissionList.concat(
    permissionList.filter((item) => item.menuName !== '征迁过程模块'),
  );

  const folderPermissionList = newPermissionList.filter(
    (item) => item.menuType === '0',
  );

  const refs = useRef([]);

  const submitForm = async () => {
    const nestedArray = refs.current.map((ref: any) =>
      ref.current?.getSubPermissions(),
    );
    const flattenedArray = nestedArray.reduce((acc, cur) => {
      return acc.concat(cur);
    }, []);

    const { err } = await giveRoles({
      data: {
        roleId,
        menuIds: flattenedArray,
        programType,
      },
    });
    if (err) return;
    onCancel();
  };

  let extraProps = {};

  if (!hasDataAuth) {
    extraProps = {
      footer: null,
    };
  }

  return (
    <MyModal
      open
      title="关联权限"
      onCancel={onCancel}
      onOk={submitForm}
      width={960}
      centered={false}
      {...extraProps}
    >
      <div
        className="associate-modal-content"
        style={{
          paddingTop: 20,
          maxHeight: 500,
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        {folderPermissionList.map((item, index) => {
          const menuPermissionList = newPermissionList.filter(
            (permission) =>
              permission.menuType === '1' &&
              permission.parentId === item.menuId,
          );

          const blockPermissions = menuPermissionList.map((menu) => {
            return {
              ...menu,
              checked: menuIds.includes(menu.menuId),
              children: newPermissionList
                .filter(
                  (permission) =>
                    (permission.menuType === '2' ||
                      permission.menuType === '1') &&
                    permission.parentId === menu.menuId,
                )
                .map((button) => {
                  return {
                    ...button,
                    checked: menuIds.includes(button.menuId),
                  };
                })
                .sort((a, b) => {
                  if (a.menuType === '2' && b.menuType !== '2') {
                    return -1;
                  }
                  if (a.menuType !== '2' && b.menuType === '2') {
                    return 1;
                  }
                  return 0;
                }),
            };
          });

          // @ts-ignore
          refs.current[index] = createRef();

          return (
            <div key={item.menuId}>
              {index !== 0 && <Divider style={{ margin: '10px 0' }} />}
              <div>
                <h3 style={{ marginBottom: 15 }}>{item.menuName}</h3>
                {blockPermissions.length > 0 && (
                  <BlockPermission
                    ref={refs.current[index]}
                    menuList={menuIds}
                    permissions={blockPermissions}
                    allList={newPermissionList}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </MyModal>
  );
};
