import { Modal } from 'antd';
import { ModalFuncProps } from 'antd/lib';
import dayjs from 'dayjs';
import { decode, encode } from 'js-base64';
import { webapp_ws_ajax_run } from './grwebapp';

export const padNumber = (num: number, length: number): string => {
  return num.toString().padStart(length, '0');
};

export const convertSpecialCharsToHtml = (text: string) => {
  if (!text) return '';
  return text
    .replace(/\n/g, '<br />')
    .replace(/\t/g, '&emsp;')
    .replace(/\r/g, '')
    .replace(/\b/g, '')
    .replace(/\f/g, '')
    .replace(/\v/g, '')
    .replace(/\0/g, '');
};

// 定义一个全局变量用于追踪模态框的显示状态
let isModalVisible = false;

export const showModalConfirm = (options: ModalFuncProps) => {
  if (isModalVisible) return; // 如果模态框已经显示，则返回
  isModalVisible = true;
  Modal.confirm({
    ...options,
    centered: true,
    onOk() {
      // 调用用户传递的 onOk 回调，并重置状态
      Promise.resolve(options.onOk?.())
        .then(() => {
          isModalVisible = false;
        })
        .catch(() => {
          isModalVisible = false;
        });
    },
    onCancel() {
      // 调用用户传递的 onCancel 回调，并重置状态
      Promise.resolve(options.onCancel?.())
        .then(() => {
          isModalVisible = false;
        })
        .catch(() => {
          isModalVisible = false;
        });
    },
    afterClose() {
      isModalVisible = false; // 确保模态框关闭后状态被重置
    },
  });
};

/**
 * 将错误滚动到可视区范围内
 * @return undefined
 */
export const scrollIntoView = () => {
  document
    .querySelector(`.ant-formily-item.ant-formily-item-error`)
    ?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
};

// Base64 URL编码
export function base64UrlEncode(input: string) {
  let base64 = encode(input);
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

// Base64 URL解码
export function base64UrlDecode(input: string) {
  let base64 = input.replace(/-/g, '+').replace(/_/g, '/');
  while (base64.length % 4 !== 0) {
    base64 += '=';
  }
  return decode(base64);
}

export function toFixedCorrectly(num: number, decimalPlaces: number) {
  const factor = Math.pow(10, decimalPlaces);
  return (Math.round((num + Number.EPSILON) * factor) / factor).toFixed(
    decimalPlaces,
  );
}

export function getHexColor(color: string) {
  if (color.length === 7) {
    return color;
  } else {
    // 提取 RGB 部分和 Alpha 部分
    const hex = color.substring(0, 7); // #0050ff
    const alphaHex = color.substring(7, 9); // 80

    // 将 RGB 部分转换为 r,g,b 数值
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);

    // 将 Alpha 转换为 0-1 的浮点数
    const alpha = parseInt(alphaHex, 16) / 255;

    // 构造 rgba 颜色
    const rgbaColor = `rgba(${r}, ${g}, ${b}, ${alpha})`;
    return rgbaColor;
  }
}

export const printOrderNum = (printData: any) => {
  const args = {
    report: '/grf/order.grf',
    data: {
      Table: [
        {
          Number: printData.num,
          printDate: dayjs(printData.createTime).format('YYYY-MM-DD HH:mm:ss'),
        },
      ],
    },
    type: 'print',
    showOptionDlg: false,
  };

  webapp_ws_ajax_run(args);
};
