import axios, { AxiosRequestConfig } from 'axios';
import { deleteImg, uploadImg, batchUploadImg } from '@/common/api';
import { Modal } from 'antd';
import store2 from 'store2';
import dayjs from 'dayjs';
import { apiBaseUrl } from '@/request';

/**
 * 获取最终的URL
 * @param fileList 文件列表
 * @param oldUrl 老的url
 * @returns
 */
export const getFileUrl = async (
  fileList: any[],
  oldUrl: string,
  extraParams?: any,
) => {
  let newUrl = '';

  if (fileList && fileList.length > 0) {
    // 原图不做处理
    if (fileList[0].response && fileList[0].response === oldUrl) {
      newUrl = oldUrl;
    } else {
      const { err, data } = await uploadImg({
        data: {
          file: fileList[0].originFileObj,
          ...extraParams,
        },
      });
      if (err) {
        newUrl = '';
        console.error('上传图片失败....');
      } else {
        newUrl = data;
      }
    }
  }

  if (oldUrl && oldUrl !== newUrl) {
    await deleteImg({
      data: [oldUrl],
    });
  }
  return newUrl;
};

// 多个文件
export const getFileUrlList = async (
  fileList: any[],
  oldUrls: string[],
  extraParams?: any,
) => {
  const newUrls = fileList.map((item) => item.response);

  const added = newUrls.filter((x) => !oldUrls.includes(x));
  const removed = oldUrls.filter((x) => !newUrls.includes(x));

  let result: string[] = oldUrls;
  if (added.length > 0) {
    const files = fileList.filter((item) => added.includes(item.response));
    let formData = new FormData();
    files.forEach((item: any) => {
      formData.append('files', item.originFileObj);
    });
    Object.keys(extraParams).forEach((key) => {
      formData.append(key, extraParams[key]);
    });
    const { err, data } = await batchUploadImg({
      data: formData,
    });
    if (!err) {
      result.push(...data);
    } else {
      console.error('上传图片失败....');
    }
  }
  if (removed.length > 0) {
    const { err } = await deleteImg({
      data: removed,
    });
    if (!err) {
      result = result.filter((item) => !removed.includes(item));
    } else {
      console.error('上传图片失败....');
    }
  }
  return result;
};

export const suffixMapper = {
  excel: 'xlsx',
  word: 'docx',
  zip: 'zip',
};

export const applicationTypeMapper = {
  excel: 'application/vnd.ms-excel',
  word: 'application/msword',
  zip: 'application/zip',
};

export const downloadFile = async ({
  url,
  type,
  fileName,
  method,
  postData,
  callback,
}: {
  url: string;
  type: 'excel' | 'word' | 'zip';
  fileName?: string;
  method?: 'GET' | 'POST';
  postData: any;
  callback?: () => void;
}) => {
  const token = store2.get('__USERTOKEN__');
  let axiosArgs: AxiosRequestConfig = {
    url: `${apiBaseUrl}${url}`,
    responseType: 'blob', // 设置响应类型为 blob
    headers: {
      Authorization: `Bearer ${token}`,
      Datasource: 'master',
    },
  };
  if (method === 'POST') {
    axiosArgs = {
      ...axiosArgs,
      method,
      data: postData,
    };
  } else {
    axiosArgs = {
      ...axiosArgs,
      method,
      params: postData,
    };
  }
  const elem = document.getElementById('global-loading-wrapper');
  if (elem) {
    elem.style.display = 'flex';
  }
  const response = await axios(axiosArgs);
  if (elem) {
    elem.style.display = 'none';
  }

  let actualFileName = '';

  if (response.headers['x-file-name']) {
    actualFileName = decodeURIComponent(response.headers['x-file-name']);
  } else {
    actualFileName = `${
      fileName ? fileName : dayjs().format('YYYYMMDDHHmmss')
    }.${suffixMapper[type]}`;
  }
  if (response.data.type === 'application/json') {
    let reader = new FileReader();
    reader.readAsText(response.data, 'utf-8');
    reader.onload = () => {
      // JSON.parse(reader.result) 转译后的json数据
      const data = JSON.parse(reader.result as string);
      if (data && data.code !== 10000) {
        Modal.error({
          title: '操作失败',
          content: data.msg || '未知原因, 请联系管理员',
          centered: true,
        });
      }
    };
  } else {
    const blob = new Blob([response.data], {
      type: applicationTypeMapper[type],
    });
    // @ts-ignore
    if (window.navigator.msSaveOrOpenBlob) {
      // 针对IE浏览器的处理，IE浏览器不支持该方法，需要使用msSaveBlob方法
      // @ts-ignore
      window.navigator.msSaveBlob(blob, actualFileName);
    } else {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = actualFileName;
      link.click();
      window.URL.revokeObjectURL(link.href);
    }
    callback && callback();
  }
};

export const downloadSingleFile = (downloadURL: string) => {
  const name = downloadURL.substring(downloadURL.lastIndexOf('/') + 1);
  axios
    .get(downloadURL, { responseType: 'blob' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name); //name为文件名称
      document.body.appendChild(link);
      link.click();
      link.remove(); //下载完将添加的临时节点删除
    })
    .catch((error) => {
      console.error(error);
    });
};
