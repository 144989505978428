import { createStyles } from 'antd-style';
import { useModel } from '@umijs/max';
import { Spin } from 'antd';
import type { MenuInfo } from 'rc-menu/lib/interface';
import React, { useCallback, useState } from 'react';
import { flushSync } from 'react-dom';
import HeaderDropdown from './HeaderDropdown';
import ChangePwdModal from './ChangePwdModal';
import { doLogout } from '@/utils';
import UserInfoModal from './UserInfoModal';

const useStyles = createStyles(({ token }) => ({
  actionClassName: {
    display: 'flex',
    height: '48px',
    marginLeft: 'auto',
    overflow: 'hidden',
    alignItems: 'center',
    padding: '0 8px',
    cursor: 'pointer',
    borderRadius: token.borderRadius,
    '&:hover': {
      backgroundColor: token.colorBgTextHover,
    },
  },
}));

export type GlobalHeaderRightProps = {
  children?: React.ReactNode;
};

export const AvatarName = () => {
  const { initialState } = useModel('@@initialState');
  const { currentUser } = initialState || {};
  return <span className="anticon">{currentUser?.nickName}</span>;
};

export const AvatarDropdown: React.FC<GlobalHeaderRightProps> = ({
  children,
}) => {
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [userInfoModalVisible, setUserInfoModalVisible] = useState(false);
  /**
   * 退出登录，并且将当前的 url 保存
   */
  const loginOut = async () => {
    doLogout();
  };

  const { initialState, setInitialState } = useModel('@@initialState');

  const onMenuClick = useCallback(
    (event: MenuInfo) => {
      const { key } = event;
      if (key === 'logout') {
        flushSync(() => {
          setInitialState((s) => ({ ...s, currentUser: undefined }));
        });
        loginOut();
        return;
      }
      if (key === 'changePwd') {
        setPasswordModalVisible(true);
        return;
      }
      if (key === 'settings') {
        setUserInfoModalVisible(true);
        return;
      }
    },
    [setInitialState],
  );

  const { styles } = useStyles();

  const loading = (
    <span className={styles.actionClassName}>
      <Spin
        size="small"
        style={{
          marginLeft: 8,
          marginRight: 8,
        }}
      />
    </span>
  );

  if (!initialState) {
    return loading;
  }

  const { currentUser } = initialState;

  if (!currentUser || !currentUser.nickName) {
    return loading;
  }

  const menuItems = [
    ...(!currentUser.admin
      ? [
          {
            key: 'settings',
            label: '个人设置',
          },
          {
            type: 'divider' as const,
          },
        ]
      : []),
    {
      key: 'changePwd',
      label: '修改密码',
    },
    {
      key: 'logout',
      label: '退出登录',
    },
  ];

  return (
    <>
      <HeaderDropdown
        menu={{
          selectedKeys: [],
          onClick: onMenuClick,
          items: menuItems,
        }}
      >
        {children}
      </HeaderDropdown>
      {passwordModalVisible && (
        <ChangePwdModal
          onCancel={() => {
            setPasswordModalVisible(false);
          }}
        />
      )}
      {userInfoModalVisible && (
        <UserInfoModal
          onCancel={() => {
            setUserInfoModalVisible(false);
          }}
          callback={(data) => {
            setInitialState((s) => ({
              ...s,
              currentUser: {
                ...s?.currentUser,
                ...data,
              },
            }));
            setUserInfoModalVisible(false);
          }}
          detailInfo={currentUser}
        />
      )}
    </>
  );
};
