import { InstitutionSideBar, MyModal } from '@/components';
import useTable from '@/hooks/useTable';
import { Form, Row, Table, message } from 'antd';
import { ColumnType } from 'antd/es/table';
import React, { useRef } from 'react';
import { getUserList } from '../api';
import SearchForm from './SearchForm';
import useCalcTableHeight from '@/hooks/useCalcTableHeight';

interface IChooseModalProps {
  pid: any; // 项目ID
  onCancel: () => void;
  callback: (data: any[]) => void;
  defaultSelectedRows: any[];
}

const columns: Array<ColumnType<any>> = [
  {
    title: '姓名',
    key: 'nickName',
    dataIndex: 'nickName',
  },
  {
    title: '手机号',
    key: 'phonenumber',
    dataIndex: 'phonenumber',
  },
  {
    title: '机构',
    key: 'sysInstitutionName',
    dataIndex: 'sysInstitutionName',
  },
];

const rowKey = 'userId';

export default (props: IChooseModalProps) => {
  const { pid, onCancel, callback, defaultSelectedRows } = props;

  const tableRef = useRef<any>(null); // 用于获取表格 DOM 元素引用
  const tableScrollY = useCalcTableHeight(tableRef); // 调用自定义 Hook

  const [form] = Form.useForm();

  const searchConditionRef = useRef<any>({});

  const fetchData = async (
    pagination: { pageNo: number; pageSize: number },
    resetPageNo?: boolean,
  ) => {
    const values = form.getFieldsValue();
    return await getUserList({
      params: {
        pageNum: resetPageNo ? 1 : pagination.pageNo,
        pageSize: pagination.pageSize,
        name: values.nickName,
        pid,
        ...searchConditionRef.current,
      },
    });
  };
  const { handleFetchData, selectedRows, ...restTableProps } = useTable<true>({
    fetchData,
    hasRowSelection: true,
    resetSelectRowKeys: false, // 翻页的时候，不需要重置选中的行数据
    defaultSelectedRows,
    rowKey,
  });

  const doSearch = () => {
    handleFetchData({ resetPageNo: true });
  };

  const submitFn = () => {
    if (selectedRows.length === 0) {
      message.error('请至少选择一条数据');
      return;
    }
    callback(selectedRows);
  };

  return (
    <MyModal
      open
      title="选择添加成员"
      onCancel={onCancel}
      onOk={submitFn}
      width={1000}
    >
      <Row
        style={{ paddingTop: 20, height: 500 }}
        className="user-manage-wrapper"
      >
        <InstitutionSideBar
          searchConditionRef={searchConditionRef}
          fetchUserList={() => {
            handleFetchData({
              resetPageNo: true,
            });
          }}
          pid={pid}
        />
        <div style={{ width: 'calc(100% - 200px)', height: 480 }}>
          <Row justify="space-between" style={{ marginBottom: 20 }}>
            <SearchForm form={form} doSearch={doSearch} />
          </Row>
          <div
            ref={tableRef}
            style={{
              height: 428,
              overflow: 'hidden',
            }}
          >
            <Table
              columns={columns}
              scroll={{
                x: 'max-content',
                y: tableScrollY > 0 ? tableScrollY : undefined, // 使用动态计算的 scroll y 值
              }}
              {...restTableProps}
            />
          </div>
        </div>
      </Row>
    </MyModal>
  );
};
